.hero-wrapper {
  background-image: url("../../assets/imgs/digital-1-1024x436.webp");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50%;
  position: relative;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 0;
}

.hero-container {
  padding: 140px 60px;
  padding-bottom: 220px;

  max-width: 1250px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hero-content {
  max-width: 750px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hero-container p {
  color: #f1f1f1;
  font-size: 18px;
  line-height: 1.6;
}

.hero-container h1 {
  font-size: 2em;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 0.05em;
  font-weight: bolder;
  color: #fff;
}

.hero-container .disclaimer {
  color: #f2f2f2ee;
}

.hero-container h1 span {
  color: #48baef;
}

@media (max-width: 768px) {
}
