.CTA-container {
  padding: 20px 10px;
  background-color: #333;
  color: #f1f1f1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}

.CTA-container .main-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}