*{
  
  font-family: "Cairo", sans-serif;
}

.body-wrapper {
  margin-top: 90px;
  min-height: 80vh;
}

.body-container {
  margin: auto;
  color: #707070;
}

p {
  letter-spacing: 0.03em;
}
a.main-btn{
  display: flex;
  align-items: center;
}

.main-btn,
.secondary-btn {
  font-family: "Cairo", sans-serif;
  border-radius: 99px;
  padding: 0 1.2em;
  font-size: 0.97em;
  text-transform: uppercase;
  text-align: center;
  min-height: 2.5em;
  font-weight: bolder;
  letter-spacing: 0.03em;
  transition: 0.3s;
  width: fit-content;
}

.main-btn {
  color: #48baef;
  border: 2px solid currentColor;
  background-color: transparent;
}

.main-btn:hover {
  background-color: #48baef;
  color: #fff;
  border-color: #48baef;
}
.secondary-btn {
  color: #f1f1f1;
  background-color: transparent;
  border: 2px solid currentColor;
}

.secondary-btn:hover {
  color: #666;
  background-color: #fff;
  border-color: #fff;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}
.disclaimer{
  color: #212a34;
  font-size: 14px;
}

.section-title-main {
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.3em 0.8em;
  text-align: center;
  width: fit-content;
  color: #212a34;
  font-size: 1.35em;
  background-color: #f1f1f1;
  text-transform: uppercase;
  border-radius: 99px;
}

.section-title b {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 2px;
  opacity: 0.1;
  background-color: #707070;
}

.whats-btn a {
  z-index: 10;
  opacity: 1;
  z-index: 0;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  padding: 10px;
  border-radius: 99px;
  transition: 0.4s;
  font-size: 60px;
  color: #48baef;
  background-color: #707070;
}

.whats-btn a:hover {
  background-color: #48baef;
  color: #707070;
}

/* Fazer aqui um template, adiantando o setup abaixo: 

main-btn, secondary-btn e minor-btn;

h1 -> configurações de título: font, size, color

h2 -> configurações de subtítulo: font, size, color

*/

.sobre-img,
.previna-img,
.sobrerip-img {
  min-height: 350px !important;
}

@media (max-width: 768px) {
  .body-wrapper {
    margin-top: 80px !important;
  }
  .header-container {
    padding: 10px 20px !important;
  }
  .hero-container {
    padding: 80px 20px !important;
    padding-bottom: 120px !important;
  }

  .sobre-content,
  .sobrerip-content,
  .previna-content {
    gap: 10px !important;
  }

  .sobre-content p,
  .sobrerip-content p,
  .previna-content p {
    font-size: 16px !important;
  }

  .sobre-content h2,
  .sobrerip-content h2,
  .previna-content h2 {
    font-size: 28px !important;
  }

  .whats-btn a{
    right: 1rem ;
    bottom: 1rem;
  }
}
