.formulario-wrapper {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

form {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  background: #fff;
  padding: 40px 30px;
  padding-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  margin-bottom: 120px;
}

form div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="checkbox"] {
  min-width: 25px;
  height: 25px;
  cursor: pointer;
}

.checkbox-list-container > div {
  display: flex;
  gap: 8px;
  margin-top: 15px;
  align-items: center;
  line-height: 1.3;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 150px;
  resize: vertical;
}

form button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  margin-left: auto;
}

form button:hover {
  background-color: #0056b3;
}

.formulario-wrapper img {
  border-radius: 20px;
}

form h1 {
  border-bottom: 1px solid #707070;
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: fit-content;
}

.btns-container {
  display: flex;
  width: fit-content;
  gap: 20px;
  margin-left: auto;
  margin-bottom: 0;
}

.agradecimento-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formulario-wrapper a {
  margin: auto;
}
