.faq-wrapper {
  padding-top: 60px;
}

.faq-container {
  margin: 20px;
  padding: 0px 20px 100px 20px;
  color: #212a34;
  max-width: 790px;
  margin: auto;
}

.faq-item {
  padding: 15px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #212a34;

}

.faq-question button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: #212a34;
    transition: 0.3s;
  cursor: pointer;
}

.faq-question button:hover{
  transform: scale(1.02);

}

.faq-question span {
  background-color: #212a34;
  color: #fff;
  font-size: 20px;
  padding: 2px 8px;
  border-radius: 5px;
}

.faq-item h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin-top: 0;
  line-height: 1.3;
}

.faq-item p {
  margin-top: 10px;
  color: #212a34e5;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.5;
}
