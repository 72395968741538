.depoimentos-container{
    min-height: 380px;
    background-image: url('../../assets/imgs/comp-verb_reduce.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 79% 68%;
    position: relative;
}

.depoimentos-container .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.65);

}

.depoimentos-container{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.depoimentos-content{
    position: relative;
    z-index: 10;
    color: #fff;
    max-width: 590px;
    text-align: center;
    padding: 0 20px;
}

.depoimentos-content h2{
    line-height: 1.2;
    font-size: 2.5rem;
}

.depoimentos-content .main-btn.alt{
    color: #fff;
    margin: auto;
    margin-top: 15px;
}

@media (max-width: 440px){
    .depoimentos-content h2{
    line-height: 1.2;
    font-size: 1.8rem;
}
}